import { starArray } from "../../components/stars/stars.type";

export const starAdvantagesMobile: starArray = [
  // left part | mobile
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "32px",
    transform: "translate(calc(-50% - 99px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "32px",
    transform: "translate(calc(-50% - 22px))",
    animationType: "short",
  },
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "100px",
    transform: "translate(calc(-50% - 131px))",
    animationType: "short",
  },
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "103px",
    transform: "translate(calc(-50% - 65px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "271px",
    left: "5px",
    animationType: "short",
  },
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "626px",
    transform: "translate(calc(-50% - 75px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "894px",
    left: "7px",
    animationType: "short",
  },
  //   right part | mobile
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "20px",
    transform: "translate(calc(-50% + 48px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "43px",
    transform: "translate(calc(-50% + 119px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "67px",
    transform: "translate(calc(-50% + 52px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "101px",
    transform: "translate(calc(-50% + 16px))",
    animationType: "short",
  },
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "117px",
    transform: "translate(calc(-50% + 64px))",
    animationType: "short",
  },
  //   ! ==================================================
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "181px",
    left: "none",
    right: "10px",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "630px",
    left: "none",
    right: "20px",
    animationType: "short",
  },
  //   ! ==================================================
];

export const starAdvantagesTablet: starArray = [
  // left part | tablet
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "2px",
    transform: "translate(calc(-50% - 189px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "26px",
    transform: "translate(calc(-50% - 257px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "67px",
    transform: "translate(calc(-50% - 317px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "98px",
    transform: "translate(calc(-50% - 22px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "141px",
    transform: "translate(calc(-50% - 192px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "187px",
    transform: "translate(calc(-50% - 189px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "205px",
    transform: "translate(calc(-50% - 304px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "205px",
    transform: "translate(calc(-50% - 304px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "265px",
    transform: "translate(calc(-50% - 97px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "301px",
    transform: "translate(calc(-50% - 247px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "347px",
    transform: "translate(calc(-50% - 244px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "365px",
    transform: "translate(calc(-50% - 359px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "517px",
    left: "33px",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "563px",
    left: "37px",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "763px",
    left: "62px",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "858px",
    left: "62px",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "1136px",
    left: "33px",
    animationType: "short",
  },

  // right part | tablet
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "24px",
    transform: "translate(calc(-50% + 185px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro-opacity-05)",
    top: "54px",
    transform: "translate(calc(-50% + 114px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "78px",
    transform: "translate(calc(-50% + 222px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "102px",
    transform: "translate(calc(-50% + 57px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "127px",
    transform: "translate(calc(-50% + 254px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "131px",
    transform: "translate(calc(-50% + 320px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "182px",
    transform: "translate(calc(-50% + 20px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "220px",
    transform: "translate(calc(-50% + 258px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "250px",
    transform: "translate(calc(-50% + 54px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "253px",
    transform: "translate(calc(-50% + 202px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "1054px",
    left: "none",
    right: "39px",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "657px",
    left: "none",
    right: "55px",
    animationType: "short",
  },
];

export const starAdvantagesDesktop: starArray = [
  // left part | desktop
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "12px",
    transform: "translate(calc(-50% - 66px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "22px",
    transform: "translate(calc(-50% - 177px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "25px",
    transform: "translate(calc(-50% - 236px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "37px",
    transform: "translate(calc(-50% - 436px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "41px",
    transform: "translate(calc(-50% - 345px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "50px",
    transform: "translate(calc(-50% - 327px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "54px",
    transform: "translate(calc(-50% - 550px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "74px",
    transform: "translate(calc(-50% - 77px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "98px",
    transform: "translate(calc(-50% - 55px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "101px",
    transform: "translate(calc(-50% - 234px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "116px",
    transform: "translate(calc(-50% - 183px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "138px",
    transform: "translate(calc(-50% - 93px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "165px",
    transform: "translate(calc(-50% - 250px))",
    animationType: "short",
  },

  // right part | desktop

  {
    type: "big",
    color: "var(--gainsboro)",
    top: "14px",
    transform: "translate(calc(-50% + 234px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "33px",
    transform: "translate(calc(-50% + 56px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "47px",
    transform: "translate(calc(-50% + 475px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "82px",
    transform: "translate(calc(-50% + 268px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "85px",
    transform: "translate(calc(-50% + 416px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "98px",
    transform: "translate(calc(-50% + 382px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "115px",
    transform: "translate(calc(-50% + 122px)) scale(1.25)",
    animationType: "short",
  },
];

export const starAdvantagesMonitor: starArray = [
  // left part | monitor
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "29px",
    transform: "translate(calc(-50% - 539px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "32px",
    transform: "translate(calc(-50% - 598px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "36px",
    transform: "translate(calc(-50% - 288px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "42px",
    transform: "translate(calc(-50% - 177px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "42px",
    transform: "translate(calc(-50% - 177px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "42px",
    transform: "translate(calc(-50% - 463px)) scale(1.5)",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "48px",
    transform: "translate(calc(-50% - 707px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-02)",
    top: "66px",
    transform: "translate(calc(-50% - 316px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "81px",
    transform: "translate(calc(-50% - 439px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "89px",
    transform: "translate(calc(-50% - 122px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "94px",
    transform: "translate(calc(-50% - 704px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "108px",
    transform: "translate(calc(-50% - 596px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "112px",
    transform: "translate(calc(-50% - 819px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "129px",
    transform: "translate(calc(-50% - 167px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "147px",
    transform: "translate(calc(-50% - 295px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "145px",
    transform: "translate(calc(-50% - 455px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "179px",
    transform: "translate(calc(-50% - 75px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "179px",
    transform: "translate(calc(-50% - 75px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "212px",
    transform: "translate(calc(-50% - 418px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "220px",
    transform: "translate(calc(-50% - 696px)) scale(1.75)",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "239px",
    transform: "translate(calc(-50% - 575px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "276px",
    transform: "translate(calc(-50% - 434px))",
    animationType: "short",
  },

  // right part | monitor
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "38px",
    transform: "translate(calc(-50% + 132px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "58px",
    transform: "translate(calc(-50% + 412px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "87px",
    transform: "translate(calc(-50% + 284px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "99px",
    transform: "translate(calc(-50% + 665px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "118px",
    transform: "translate(calc(-50% + 193px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "129px",
    transform: "translate(calc(-50% + 107px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "131px",
    transform: "translate(calc(-50% + 448px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "134px",
    transform: "translate(calc(-50% + 605px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "163px",
    transform: "translate(calc(-50% + 16px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "167px",
    transform: "translate(calc(-50% + 292px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "187px",
    transform: "translate(calc(-50% + 53px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "212px",
    transform: "translate(calc(-50% + 639px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "243px",
    transform: "translate(calc(-50% + 547px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "254px",
    transform: "translate(calc(-50% + 461px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "256px",
    transform: "translate(calc(-50% + 802px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "292px",
    transform: "translate(calc(-50% + 646px))",
    animationType: "short",
  },
];
