import { starArray } from "../../components/stars/stars.type";

// left => - 187 + x(left) // -50% - res
// right => 187 - x(left) // -50% + res

export const starHeroMobile: starArray = [
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "307px",
    transform: "translate(calc(-50% + 174px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "381px",
    transform: "translate(calc(-50% - 159px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "402px",
    transform: "translate(calc(-50% + 142px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "438px",
    transform: "translate(calc(-50% - 161px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "448px",
    transform: "translate(calc(-50% + 72px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "456px",
    transform: "translate(calc(-50%))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "458px",
    transform: "translate(calc(-50% - 77px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro-opacity-05)",
    top: "456px",
    transform: "translate(calc(-50% - 143px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "475px",
    transform: "translate(calc(-50% - 120px))",
    animationType: "short",
  },
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "498px",
    transform: "translate(calc(-50% - 172px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "514px",
    transform: "translate(calc(-50% - 142px))",
    animationType: "short",
  },
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "568px",
    transform: "translate(calc(-50% - 118px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "578px",
    transform: "translate(calc(-50% - 177px))",
    animationType: "short",
  },
  {
    type: "small",
    color: "var(--gainsboro)",
    top: "460px",
    transform: "translate(calc(-50% + 155px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "491px",
    transform: "translate(calc(-50% + 119px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "516px",
    transform: "translate(calc(-50% + 131px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "548px",
    transform: "translate(calc(-50% + 112px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "550px",
    transform: "translate(calc(-50% + 176px))",
    animationType: "short",
  },
];

export const starHeroTablet: starArray = [
  // left part | tablet
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "406px",
    transform: "translate(calc(-50% - 336px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "489px",
    transform: "translate(calc(-50% - 276px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "530px",
    transform: "translate(calc(-50% - 334px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "599px",
    transform: "translate(calc(-50% - 321px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "612px",
    transform: "translate(calc(-50% - 138px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "632px",
    transform: "translate(calc(-50% - 102px))",
    animationType: "short",
  },
  // right part | tablet
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "353px",
    transform: "translate(calc(-50% + 283px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "381px",
    transform: "translate(calc(-50% + 354px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "477px",
    transform: "translate(calc(-50% + 291px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "494px",
    transform: "translate(calc(-50% + 248px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "532px",
    transform: "translate(calc(-50% + 182px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "604px",
    transform: "translate(calc(-50% + 284px))",
    animationType: "short",
  },
];

export const starHeroDesktop: starArray = [
  // left part | desktop
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "168px",
    transform: "translate(calc(-50% - 603px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro-opacity-05)",
    top: "313px",
    transform: "translate(calc(-50% - 460px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "335px",
    transform: "translate(calc(-50% - 567px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "348px",
    transform: "translate(calc(-50% - 384px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-02)",
    top: "368px",
    transform: "translate(calc(-50% - 348px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "394px",
    transform: "translate(calc(-50% - 438px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "418px",
    transform: "translate(calc(-50% - 507px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "445px",
    transform: "translate(calc(-50% - 345px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "459px",
    transform: "translate(calc(-50% - 565px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "463px",
    transform: "translate(calc(-50% - 140px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "470px",
    transform: "translate(calc(-50% - 96px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "501px",
    transform: "translate(calc(-50% - 610px)) scale(2)",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "517px",
    transform: "translate(calc(-50% - 333px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "533px",
    transform: "translate(calc(-50% - 442px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "534px",
    transform: "translate(calc(-50% - 238px))",
    animationType: "short",
  },
  // right part | desktop
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "128px",
    transform: "translate(calc(-50% + 608px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "237px",
    transform: "translate(calc(-50% + 601px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "395px",
    transform: "translate(calc(-50% + 472px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "473px",
    transform: "translate(calc(-50% + 192px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "477px",
    transform: "translate(calc(-50% + 274px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "489px",
    transform: "translate(calc(-50% + 103px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "502px",
    transform: "translate(calc(-50% + 471px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "506px",
    transform: "translate(calc(-50% + 537px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "519px",
    transform: "translate(calc(-50% + 392px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "540px",
    transform: "translate(calc(-50% + 432px))",
    animationType: "short",
  },
];

export const starHeroMonitor: starArray = [
  // left part | monitor
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "637px",
    transform: "translate(calc(-50% - 492px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "661px",
    transform: "translate(calc(-50% - 353px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "661px",
    transform: "translate(calc(-50% - 836px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "689px",
    transform: "translate(calc(-50% - 233px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "690px",
    transform: "translate(calc(-50% - 653px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-02)",
    top: "710px",
    transform: "translate(calc(-50% - 617px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "726px",
    transform: "translate(calc(-50% - 330px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "736px",
    transform: "translate(calc(-50% - 707px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "754px",
    transform: "translate(calc(-50% - 502px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "760px",
    transform: "translate(calc(-50% - 776px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "761px",
    transform: "translate(calc(-50% - 458px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "799px",
    transform: "translate(calc(-50% - 323px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "801px",
    transform: "translate(calc(-50% - 834px))",
    animationType: "short",
  },
  {
    type: "medium",
    color: "var(--gainsboro)",
    top: "825px",
    transform: "translate(calc(-50% - 600px))",
    animationType: "short",
  },

  // right part | monitor
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "397px",
    transform: "translate(calc(-50% + 888px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "519px",
    transform: "translate(calc(-50% + 880px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "696px",
    transform: "translate(calc(-50% + 735px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "713px",
    transform: "translate(calc(-50% + 665px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro-opacity-05)",
    top: "749px",
    transform: "translate(calc(-50% + 516px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "796px",
    transform: "translate(calc(-50% + 366px))",
    animationType: "short",
  },
  {
    type: "large",
    color: "var(--gainsboro)",
    top: "799px",
    transform: "translate(calc(-50% + 91px)) scale(1.25)",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "800px",
    transform: "translate(calc(-50% + 458px)) scale(2.25)",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "827px",
    transform: "translate(calc(-50% + 664px))",
    animationType: "short",
  },
  {
    type: "big",
    color: "var(--gainsboro)",
    top: "831px",
    transform: "translate(calc(-50% + 734px))",
    animationType: "short",
  },
];
