import React from "react";

export default function IconArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"100%"}
      height={"100%"}
      viewBox="0 0 16 46"
      fill="none"
    >
      <path
        d="M7.29289 45.7071C7.68342 46.0976 8.31658 46.0976 8.70711 45.7071L15.0711 39.3431C15.4616 38.9526 15.4616 38.3195 15.0711 37.9289C14.6805 37.5384 14.0474 37.5384 13.6569 37.9289L8 43.5858L2.34315 37.9289C1.95262 37.5384 1.31946 37.5384 0.928932 37.9289C0.538408 38.3195 0.538408 38.9526 0.928932 39.3431L7.29289 45.7071ZM7 0L7 45H9L9 0L7 0Z"
        fill="#E8E8E8"
      />
    </svg>
  );
}
